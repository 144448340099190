import { Typography } from 'antd';
import { TitleProps as AntdTitleProps } from 'antd/lib/typography/Title';
import classNames from 'classnames';
import React from 'react';

import { presets, TypographyPresets } from './Preset';

const AntdTitle = Typography.Title;

interface TitleProps extends AntdTitleProps {
  preset?: TypographyPresets;
  className?: string;
}

const Title: React.FC<TitleProps> = ({ preset, className, ...props }) => {
  return <AntdTitle className={classNames('text', preset && presets[preset], className)} {...props} />;
};

export default Title;
