/* eslint-disable @typescript-eslint/no-var-requires */

export const icons = {
  facebook: require('#assets/icons/social/Facebook.svg').default,
  'facebook-dark': require('#assets/icons/social/FacebookDark.svg').default,
  twitter: require('#assets/icons/social/Twitter.svg').default,
  'twitter-dark': require('#assets/icons/social/TwitterDark.svg').default,
  'instagram-dark': require('#assets/icons/social/InstagramDark.svg').default,
  'youtube-dark': require('#assets/icons/social/YoutubeDark.svg').default,
  menu: require('#assets/icons/Menu.svg').default,
  close: require('#assets/icons/Close.svg').default,
  search: require('#assets/icons/Search.svg').default,
  user: require('#assets/icons/User.svg').default,
  'creator-profile': require('#assets/icons/CreatorProfile.svg').default,
  referral: require('#assets/icons/Referral.svg').default,
  payment: require('#assets/icons/Payment.svg').default,
  dashboard: require('#assets/icons/Dashboard.svg').default,
  'user-circle': require('#assets/icons/UserCircle.svg').default,
  warning: require('#assets/icons/Warning.svg').default,
  'warning-filled': require('#assets/icons/WarningFilled.svg').default,
  'cash-toggle': require('#assets/icons/CashToggle.svg').default,
  'cash-toggle-1': require('#assets/icons/CashToggle1.svg').default,
  'credit-card-lock': require('#assets/icons/CreditCardLock.svg').default,
  'hands-heart': require('#assets/icons/HandsHeart.svg').default,
  upload: require('#assets/icons/Upload.svg').default,
  play: require('#assets/icons/Play.svg').default,
  lock: require('#assets/icons/Lock.svg').default,
  date: require('#assets/icons/Date.svg').default,
  'check-circle': require('#assets/icons/CheckCircle.svg').default,
  'arrow-down': require('#assets/icons/ArrowDown.svg').default,
  'arrow-up': require('#assets/icons/ArrowUp.svg').default,
  'arrow-right': require('#assets/icons/ArrowRight.svg').default,
  'arrow-left': require('#assets/icons/ArrowLeft.svg').default,
  'add-circle': require('#assets/icons/AddCircle.svg').default,
  'add-outline': require('#assets/icons/AddOutline.svg').default,
  add: require('#assets/icons/Add.svg').default,
  'close-circle': require('#assets/icons/CloseCircle.svg').default,
  hamburger: require('#assets/icons/Hamburger.svg').default,
  back: require('#assets/icons/Back.svg').default,
  fullscreen: require('#assets/icons/Fullscreen.svg').default,
  video: require('#assets/icons/Video.svg').default,
  photo: require('#assets/icons/Photo.svg').default,
  more: require('#assets/icons/More.svg').default,
  purchase: require('#assets/icons/Purchase.svg').default,
  'credit-card': require('#assets/icons/CreditCard.svg').default,
  star: require('#assets/icons/Star.svg').default,
  edit: require('#assets/icons/Edit.svg').default,
};

export type IconName = keyof typeof icons;
