import { Typography } from 'antd';
import { TextProps as AntdTextProps } from 'antd/lib/typography/Text';
import classNames from 'classnames';
import React from 'react';

import { presets, TypographyPresets } from './Preset';

const AntdText = Typography.Text;

interface TextProps extends AntdTextProps {
  preset?: TypographyPresets;
  className?: string;
}

const Text: React.FC<TextProps> = ({ preset, className, ...props }) => {
  return <AntdText className={classNames('text', preset && presets[preset], className)} {...props} />;
};

export default Text;
