import { Typography } from 'antd';
import { ParagraphProps as AntdParagraphProps } from 'antd/lib/typography/Paragraph';
import classNames from 'classnames';
import React from 'react';

import { presets, TypographyPresets } from './Preset';

const AntdParagraph = Typography.Paragraph;

interface ParagraphProps extends AntdParagraphProps {
  preset?: TypographyPresets;
  className?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ preset, className, ...props }) => {
  return <AntdParagraph className={classNames('text', preset && presets[preset], className)} {...props} />;
};

export default Paragraph;
