export const presets = {
  regular10: 'text--regular10',
  regular12: 'text--regular12',
  regular14: 'text--regular14',
  regular16: 'text--regular16',
  regular18: 'text--regular18',
  regular20: 'text--regular20',

  medium12: 'text--medium12',
  medium14: 'text--medium14',
  medium16: 'text--medium16',
  medium18: 'text--medium18',
  medium20: 'text--medium20',
  medium24: 'text--medium24',

  semibold12: 'text--semibold12',
  semibold14: 'text--semibold14',
  semibold16: 'text--semibold16',
  semibold18: 'text--semibold18',
  semibold20: 'text--semibold20',
  semibold24: 'text--semibold24',
  semibold28: 'text--semibold28',
  semibold32: 'text--semibold32',
  semibold40: 'text--semibold40',

  bold12: 'text--bold12',
  bold14: 'text--bold14',
  bold16: 'text--bold16',
  bold18: 'text--bold18',
  bold20: 'text--bold20',
  bold24: 'text--bold24',
  bold28: 'text--bold28',
  bold32: 'text--bold32',
  bold40: 'text--bold40',
  bold56: 'text--bold56',
  bold72: 'text--bold72',
};

export type TypographyPresets = keyof typeof presets;
