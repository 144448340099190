import { Typography } from 'antd';
import { LinkProps as AntdLinkProps } from 'antd/lib/typography/Link';
import classNames from 'classnames';
import React from 'react';

import { presets, TypographyPresets } from './Preset';

const AntdLink = Typography.Link;

interface LinkProps extends AntdLinkProps {
  preset?: TypographyPresets;
  className?: string;
}

const Link: React.FC<LinkProps> = ({ preset, className, ...props }) => {
  return <AntdLink className={classNames('text', preset && presets[preset], className)} {...props} />;
};

export default Link;
