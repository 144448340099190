import classNames from 'classnames';
import React from 'react';

import { IconName, icons } from './Icons';

export interface IconProps {
  style?: Partial<CSSStyleDeclaration>;
  className?: string;
  name: IconName;
  stroke?: string;
  fill?: string;
  opacity?: number;
  width?: number;
  height?: number;
}

const Icon = (props: IconProps): JSX.Element => {
  const { name, className, ...rest } = props;

  const IconSVG = icons[name];

  return (
    <span className={classNames('icon', className)}>
      <style jsx>{`
        svg > :global(path) {
          ${props.stroke ? `stroke: ${props.stroke};` : ''}
          ${props.fill ? `fill: ${props.fill};` : ''}
                ${props.opacity ? `opacity: ${props.opacity};` : ''}
        }
        svg > :global(rect) {
          ${props.stroke ? `stroke: ${props.stroke};` : ''}
          ${props.fill ? `fill: ${props.fill};` : ''}
                ${props.opacity ? `opacity: ${props.opacity};` : ''}
        }
      `}</style>
      <IconSVG {...rest} />
    </span>
  );
};

export default Icon;
